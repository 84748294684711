import { Box, Button, Divider, Flex, Grid, GridItem, Heading, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { IncrementAmountInput, UnitSelection, NextLink, Wrap } from '../../'
import type { IImage, IHeading } from '../../'
import { formatHeadlineColor, CustomHeading } from '@stocker/ui-components/helpers'
import uniqBy from 'lodash/uniqBy'
import NextImage from 'next/image'
import { useActiveChannelQuery, useActiveCustomerRolesQuery, useCustomerSpecificPriceQuery } from '@stocker/codegen/vendure'
import type React from 'react'
import { useState } from 'react'
import { FormattedNumber, useIntl } from 'react-intl'

export interface ICartProductItemProps {
  image: IImage
  heading: IHeading
  originalSinglePrice?: number
  singlePrice: number
  linePrice?: number
  currencyCode?: string
  detailLink?: string
  amountAvailable?: number
  sku?: string
  actions?: Array<{
    buttonText: string
    buttonIcon?: React.ReactNode
    buttonFunction?: (amount: number) => void
  }>
  packageUnits?: string[]
  currentAmount?: number
  id?: string
  onAmountChange?: (amount: number) => void
  disableInteractions?: boolean
  facetValues?: Array<{
    facetName: string
    facetValue: string
  }>
  alwaysMobile?: boolean
}

export const CartProductItem: React.FC<ICartProductItemProps> = ({ alwaysMobile, originalSinglePrice, facetValues, disableInteractions = false, currencyCode = 'eur', onAmountChange, currentAmount, actions, linePrice, image, heading, detailLink, sku, singlePrice, amountAvailable, packageUnits, id }) => {
  const [amount, setAmount] = useState(currentAmount ?? 1)
  const [packageUnit, setPackageUnit] = useState(packageUnits ? packageUnits[0] : undefined)
  const { data: activeChannel } = useActiveChannelQuery()
  const { formatMessage } = useIntl()
  const { data: activeCustomer } = useActiveCustomerRolesQuery()

  const handleAmountChange = (newAmount: number) => {
    setAmount(newAmount)
    if (onAmountChange) {
      onAmountChange(newAmount)
    }
  }

  // This is for the wishlist since there we have to seperately fetch the customerSpecific price
  const { data: customerSpecificPrice } = useCustomerSpecificPriceQuery({
    channelCode: activeChannel?.activeChannel.code ?? 'EU',
    variantId: String(id),
  })

  const inFullAccessGroup = !!activeCustomer?.activeCustomer?.groups.find((group) => group.name === 'FULL_ACCESS')

  if (!originalSinglePrice && activeCustomer?.activeCustomer?.customFields?.useCSP && inFullAccessGroup) {
    if (customerSpecificPrice?.customerSpecificPrice?.price) {
      singlePrice = customerSpecificPrice.customerSpecificPrice.price
    }
    originalSinglePrice = customerSpecificPrice?.customerSpecificPrice?.basePrice
  }

  const intl = useIntl()

  return (
    <>
      {/* DEKSTOP VIEW */}
      <Grid templateColumns={(disableInteractions || !linePrice) ? 'repeat(11, 1fr)' : 'repeat(13, 1fr)'} gap={4} display={alwaysMobile ? 'none' : { base: 'none', lg: 'grid' }}>
        <GridItem colSpan={5}>
          <Stack direction="row" h="100%" align="center">
            <Flex
              justify="center"
              align="center"
              pos="relative"
              h="100%"
              w="100%"
              minW="50px"
              minH="50px"
              maxW="60px"
              maxH="60px"
              mx="10px"
            >
              <Wrap
                if={true}
                with={(children) => <NextLink href={detailLink}>{children}</NextLink>}
              >
                <NextImage
                  width="60"
                  height="60"
                  src={image.fullpath ?? ''}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                  alt="Product Image"
                />
              </Wrap>
            </Flex>
            <Wrap
              if={true}
              with={(children) => <NextLink as="span" href={detailLink}>{children}</NextLink>}
            >
              <Stack spacing={0}>
                <Wrap
                  if={!disableInteractions}
                  with={(children) => <NextLink href={detailLink}>{children}</NextLink>}
                >
                  <Box display="inline">
                    <CustomHeading heading={{
                      text: formatHeadlineColor(heading.text as string),
                      type: heading.type,
                      size: heading.size,
                      color: 'secondaryText.500',
                    }}
                    />
                  </Box>
                  {facetValues && (
                    <HStack divider={<Box border="none">/</Box>}>
                      {uniqBy(facetValues, 'facetName').map((facetValue, index) => (
                        <Text key={index}>
                          {formatMessage({ id: `--facet-${String(facetValue.facetName)}` })}: {facetValue.facetValue}
                        </Text>
                      ))}
                    </HStack>
                  )}
                  {sku && (
                    <Text fontSize="sm" color="accent.500">
                      {intl.formatMessage({ id: '--sku' })}: {sku}
                    </Text>
                  )}
                </Wrap>
                {packageUnits && (
                  <HStack w="fit-content" align="center" pt="10px">
                    <Text>VPE</Text>
                    <UnitSelection units={packageUnits} onChangeAmount={setPackageUnit} selectedUnit={packageUnit ?? ''}/>
                  </HStack>
                )}
              </Stack>
            </Wrap>
          </Stack>
        </GridItem>
        <GridItem colSpan={2} alignSelf="center">
          {originalSinglePrice && originalSinglePrice !== singlePrice && (
            <HStack>
              <Text display="inline" fontSize="sm" color="red" as="del" fontFamily="primary">
                <FormattedNumber
                  value={originalSinglePrice / 100}
                  style="currency"
                  currency={currencyCode}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Text>
              <Text display="inline" color="red" fontFamily="primary">
                {`${((singlePrice - originalSinglePrice) * 100 / (originalSinglePrice)).toFixed(0)}%`}
              </Text>
            </HStack>
          )}
          <Text fontFamily="primary">
            <FormattedNumber
              value={singlePrice / 100}
              style="currency"
              currency={currencyCode}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </Text>
        </GridItem>
        <GridItem colSpan={2} alignSelf="center">
          {disableInteractions
            ? (
              <Box>{amount}</Box>
              )
            : (
              <IncrementAmountInput min={1} max={amountAvailable ?? undefined} amount={amount} onChange={handleAmountChange}/>
              )}
        </GridItem>
        {linePrice && (
          <GridItem colSpan={2} alignSelf="center">
            <Text fontFamily="primary">
              <FormattedNumber
                value={linePrice / 100}
                style="currency"
                currency={currencyCode}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Text>
          </GridItem>
        )}
        <GridItem colSpan={2} alignSelf="center">
          <Stack>
            {actions?.map((action, index) => (
              <Button key={index} w="fit-content" onClick={() => action.buttonFunction?.(amount)}>
                <HStack>
                  {action.buttonIcon}
                  <Text>
                    {action.buttonText}
                  </Text>
                </HStack>
              </Button>
            ))}
          </Stack>
        </GridItem>
      </Grid>
      {/* MOBILE VIEW */}
      <Stack spacing="10px" display={alwaysMobile ? 'inline' : { base: 'inline', lg: 'none' }}>
        <Stack direction="row" justify="space-between">
          <Box>
            <Stack direction="row" pb="10px">
              <Flex
                className="not-visible-when-printing"
                justify="center"
                align="center"
                pos="relative"
                h="50px"
                w="50px"
              >
                <NextLink href={detailLink}>
                  <NextImage
                    src={image.fullpath ?? ''}
                    fill
                    sizes="100vw"
                    style={{
                      objectFit: 'contain',
                    }}
                    alt="Product Image"
                  />
                </NextLink>
              </Flex>
              <Stack spacing={0}>
                <NextLink href={detailLink}>
                  <Heading as={heading.type} fontSize={heading.size}>
                    {heading.text}
                  </Heading>
                  {facetValues && (
                    <VStack spacing={0} align="left">
                      {facetValues.map((facetValue, index) => (
                        <Text key={index}>
                          {formatMessage({ id: `--facet-${facetValue.facetName}` })}: {facetValue.facetValue}
                        </Text>
                      ))}
                    </VStack>
                  )}
                  {sku && (
                    <Text fontSize="sm" color="accent.500">
                      {`${intl.formatMessage({ id: '--sku' })}: ${sku}`}
                    </Text>
                  )}
                </NextLink>
                {packageUnits && (
                  <HStack w="fit-content" align="center" pt="10px">
                    <Text>VPE</Text>
                    <UnitSelection units={packageUnits} onChangeAmount={setPackageUnit} selectedUnit={packageUnit ?? ''}/>
                  </HStack>
                )}
              </Stack>
            </Stack>
            {disableInteractions
              ? (
                <Box>{formatMessage({ id: '--amount' })}: {amount}</Box>
                )
              : (
                <IncrementAmountInput min={1} max={amountAvailable ?? undefined} amount={amount} onChange={handleAmountChange}/>
                )}

          </Box>
          <Stack justify="space-between">
            {actions?.map((action, index) => (
              <Box key={index} className="not-visible-when-printing" textAlign="right" fontSize="lg" onClick={() => action.buttonFunction?.(amount)} cursor="pointer">
                {action.buttonIcon}
              </Box>
            ))}
            <Box fontFamily="primary" textAlign="right">
              <Text fontSize="sm" color="secondaryText.200">
                Einzelpreis
              </Text>
              {originalSinglePrice && originalSinglePrice !== singlePrice && (
                <Text display="inline" fontSize="sm" color="red" as="del" fontFamily="primary">
                  <FormattedNumber
                    value={originalSinglePrice / 100}
                    style="currency"
                    currency={currencyCode}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Text>
              )}
              <HStack justify="flex-end">
                <Text display="none" className="only-visible-when-printing" whiteSpace="nowrap">{amount} x</Text>
                <Text fontSize="md">
                  <FormattedNumber
                    value={singlePrice / 100}
                    style="currency"
                    currency={currencyCode}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Text>
              </HStack>
            </Box>
            {linePrice && (
              <Text fontSize="lg" fontFamily="primary" textAlign="right">
                <FormattedNumber
                  value={linePrice / 100}
                  style="currency"
                  currency={currencyCode}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Text>
            )}
          </Stack>
        </Stack>
        <Divider borderWidth="1px" borderColor="gray.300"/>
      </Stack>
    </>
  )
}
